<template>
  <section class="py-4 px-5">
    <h3 class="page-header">Cities</h3>
    <b-form-row class="my-4 justify-content-between">
      <b-col lg="7" sm="7" cols="12">
        <div class="floating-input-field">
          <b-form-input id="form-input-search-cities" class="mb-2 mb-sm-1" v-model="filter" @input="currentPage = 1" placeholder=" " type="text"></b-form-input>
          <label for="form-input-search-cities">Search city <BIconSearch class="input-search-icon" /></label>
        </div>
      </b-col>
      <b-col lg="2" sm="5" cols="12" class="mb-2 mb-sm-1">
        <b-button block type="button" v-activeBlur variant="primary" @click="navigate('cities/add-city')"><BIconPlus scale="1.5" /> Add city</b-button>
      </b-col>
    </b-form-row>
    <!-- Cities table element -->
    <b-table
      :items="cities"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @context-changed="setTableCache($event, 'cities')"
      @row-dblclicked="onCityRowDblclicked"
      show-empty
      @filtered="onFiltered"
      hover
      class="table-transition"
      id="cities-table"
      primary-key="tenantID"
      :tbody-transition-props="transProps"
      :busy="citiesLoading"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>

      <template #cell(footerLogo)="row">
        <div class="preview-logo-wraper" v-if="row.item.footerLogo">
          <div class="preview-logo" v-html="row.item.footerLogo"></div>
        </div>
        <div class="preview-logo-wraper-img" v-else>
          <img class="preview-logo" :src="row.item.footerLogoURI" />
        </div>
      </template>
      <template #cell(editCity)="row">
        <router-link :to="`cities/edit-city/${row.item.tenantID}`" class="theme-font-bold"> Edit city </router-link>
      </template>
    </b-table>
    <!-- Table options-->
    <b-form-row>
      <b-col sm="6" md="6" lg="3" cols="12" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="5"
          label-cols-md="6"
          label-cols-lg="4"
          label-cols-xl="3"
          label-cols="3"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="d-none d-lg-block"></b-col>
      <b-col sm="6" md="6" lg="3" class="my-1">
        <b-pagination
          v-if="!citiesLoading"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-form-row>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { BIconPlus, BIconSearch } from 'bootstrap-vue'
import { useTableOptionsCache } from '@/composables/useTableOptionsCache'
export default {
  name: 'City',
  setup() {
    const { filter, perPage, currentPage, sortBy, sortDesc, getTableCache, setTableCache } = useTableOptionsCache('cities')
    return { filter, perPage, currentPage, sortBy, sortDesc, getTableCache, setTableCache }
  },
  data() {
    return {
      transProps: {
        // Transition name
        name: 'flip-list',
      },
      citiesLoading: true,
      fields: [
        {
          key: 'footerLogo',
          label: 'Logo',
        },
        {
          key: 'cityName',
          label: 'City name',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'appName',
          label: 'App name',
          sortable: true,
        },
        {
          key: 'widgetCnt',
          label: 'Total widgets',
          sortable: true,
        },
        { key: 'editCity', label: '' },
      ],
      totalRows: 1,
      pageOptions: [5, 10, 15, 25],
      sortDirection: 'asc',
      filterOn: ['cityName', 'appName'],
    }
  },
  components: {
    BIconPlus,
    BIconSearch,
  },
  computed: {
    ...mapState({
      cities: (state) => state.cities.cities,
    }),
  },
  created() {
    this.getCities()
  },
  methods: {
    navigate(url) {
      this.$router.push(url).catch(() => {})
    },
    onCityRowDblclicked(item) {
      this.$router.push(`cities/edit-city/${item.tenantID}`).catch(() => {})
    },
    getCities() {
      this.citiesLoading = true
      this.$store
        .dispatch('cities/getCities')
        .then((response) => {
          this.citiesLoading = false
        })
        .catch((erro) => {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'CITIES_LIST_ERROR',
            type: 'danger',
          })
          this.citiesLoading = false
          this.totalRows = 0
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
    },
  },
  watch: {
    cities() {
      this.totalRows = this.cities.length
      this.getTableCache('cities')
    },
  },
}
</script>
<style lang="scss" scoped></style>
